import React from 'react'
import { Helmet } from 'react-helmet'
import { Fade } from 'react-reveal';
import Layout from '../../components/layout'

const Drones = props => (
    <Layout>
        <Helmet>
            <title>Drone Technology</title>
            <meta name="description" content="Carboncrediting Info" />
        </Helmet>
        <Fade duration={2500}>
            <div id="main" className="wrapper style1">
                <div className="container">
                    <header className="major">
                        <h2>Drone Technology</h2>
                        <p>
                        Usage of drone technologies for mapping &amp; scoping lands for efficient farming practices.
                        </p>
                    </header>
                    <br/>
                    <section>
                        <div className="col-4 col-6-medium col-12-small col-12-xsmall">
                            <div style={{textAlign:`center`}}><b>More information will be available soon! Stay Tuned!</b></div>
                                
                        </div>
                    </section>
                
                </div>
            </div>
        </Fade>
    </Layout>
)

export default Drones